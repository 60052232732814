import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { StepFormContainer, StepText, StepTitle } from '@shared/ui/sidebars/shared';
import Input from '@shared/ui/inputs/Input';
import Button from '@shared/ui/buttons/Button';
import QuantityInput from '@shared/ui/inputs/QuantityInput';
import { PortfolioType } from '@entities/portfolio/model/Portfolio';
import IconText from '@shared/ui/icons/IconText';
import { ReactComponent as TriangleIcon } from '@icons/wolfkit-light/alert-triangle-light.svg';
import Icon from '@shared/ui/icons/Icon';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import WalletSelect from '@shared/ui/inputs/WalletSelect';
import { PortfolioCard } from '@entities/portfolio';
import usePortfolioCreate from '../usePortfolioCreate';
import { portfolioSettingsValidationSchema } from './validation';
import { GROUPED_MOCKS } from '../mocks';
const MonthlyFeeIncDecStep = 5;
const InputContainer = styled.div(props => ({
    width: '100%',
    marginBottom: props.theme.spacing_sizes.l,
}));
const SelectContainer = styled(InputContainer)();
const MonthlyFeeContainer = styled(InputContainer)(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 6.75,
}));
const IconTextStyled = styled(IconText)(props => ({
    alignSelf: 'center',
    '& .MuiTypography-root': {
        fontSize: 12,
        color: props.theme.palette.warning.main,
    },
}));
const PortfolioContainer = styled(InputContainer)(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 1.25,
}));
const PortfolioSettings = () => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const { goToNextStep, currentUserProfile, sidebarFormValues, handleClose, displayBackBtn, goToPrevStep, } = usePortfolioCreate();
    const theme = useTheme();
    const { handleSubmit, control, watch, formState: { errors }, setValue, trigger, } = useForm({
        defaultValues: sidebarFormValues,
        resolver: yupResolver(portfolioSettingsValidationSchema(t, GROUPED_MOCKS)),
    });
    const monthlyFee = watch('monthlyFee');
    const portfolioName = watch('portfolioName');
    const walletId = watch('walletId');
    const hasErrors = (errors.monthlyFee &&
        errors.monthlyFee.message && ((_b = (_a = errors.monthlyFee) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.length) > 0) ||
        (errors.portfolioName &&
            errors.portfolioName.message && ((_c = errors.portfolioName.message) === null || _c === void 0 ? void 0 : _c.length) > 0) ||
        (errors.walletId &&
            errors.walletId.message && ((_e = (_d = errors.walletId) === null || _d === void 0 ? void 0 : _d.message) === null || _e === void 0 ? void 0 : _e.length) > 0);
    const walletError = errors.walletId &&
        errors.walletId.message ? errors.walletId.message : undefined;
    const portfolio = useMemo(() => ({
        id: '123',
        name: (portfolioName === null || portfolioName === void 0 ? void 0 : portfolioName.length) ? portfolioName : 'Portfolio Name',
        chat_activity: 0,
        copiers_count: 174,
        favorited_count: 0,
        followers: {
            count: 10,
            average_followers_monthly_return: 70.29,
            average_followers_monthly_return_first: 84.9,
            average_followers_monthly_return_last: 64.5,
            preview_followers: [],
        },
        monthly_following_fee: monthlyFee,
        graph_data: [],
        has_error: false,
        is_archived: false,
        logo: '',
        min_balance: 35,
        min_balance_currency: '$',
        minimum_balance_type: '1_to_1',
        visibility: 'public',
        viewed: 0,
        past_month_difference: 83.75,
        past_ninety_days_difference: 153.89,
        monthly_following_fee_currency: '$',
        overall_difference: 153.89,
        profile: Object.assign({}, (currentUserProfile || {})),
        profile_id: '1',
        owner_profile_id: '1',
        parent_exchange_id: '1',
        type: PortfolioType.Spot,
        referral_asset: 'usdt',
        past_week_difference: 4,
        past_month_start_date: 'Jan 4, 2021',
        past_month_end_date: 'Dec 3, 2021',
        whole_period_days_count: 90,
        shared_count: 5,
        traders_balance: 0,
        traders_balance_currency: '$',
        is_in_assessment_review: true,
    }), [monthlyFee, portfolioName, currentUserProfile]);
    const handleWalletSelect = (_ev, itemId) => {
        setValue('walletId', itemId);
        trigger('walletId');
    };
    const onSubmit = (values) => {
        goToNextStep(values);
    };
    return (_jsx(SidebarInner, { cancelBtnProps: { onClick: handleClose }, title: (displayBackBtn ? (_jsx(BackLinkButton, { onClick: goToPrevStep, children: t('portfolio.create_portfolio.back_btn', { ns: 'common' }) })) : undefined), displayCancelBtn: true, children: _jsx(StepFormContainer, { onSubmit: handleSubmit(onSubmit), title: (_jsx(StepTitle, { children: t('portfolio.create_portfolio.steps.portfolio_settings.title', { ns: 'common' }) })), content: (_jsxs(_Fragment, { children: [_jsx(StepText, { text: t('portfolio.create_portfolio.steps.portfolio_settings.step_text', { ns: 'common' }), stepNumber: t('portfolio.create_portfolio.steps.portfolio_settings.step_number', { ns: 'common' }) }), _jsx(SelectContainer, { children: _jsx(WalletSelect, { label: t('portfolio.create_portfolio.steps.portfolio_settings.parent_wallet.label', { ns: 'common' }), items: GROUPED_MOCKS, value: walletId || '', onChange: handleWalletSelect, notification: walletError, notificationStatus: (walletError === null || walletError === void 0 ? void 0 : walletError.length) ? 'error' : undefined }) }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'portfolioName', control: control, render: ({ field, fieldState }) => {
                                var _a;
                                return (_jsx(Input, Object.assign({}, field, { label: t('portfolio.create_portfolio.steps.portfolio_settings.portfolio_name.label', { ns: 'common' }), notificationStatus: ((_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message) ? 'error' : undefined, maxCount: 30, required: true })));
                            } }) }), _jsx(MonthlyFeeContainer, { children: _jsx(Controller, { name: 'monthlyFee', control: control, render: ({ field }) => (_jsx(QuantityInput, { label: t('portfolio.create_portfolio.steps.portfolio_settings.monthly_fee.label', { ns: 'common' }), size: 'small', incDecStep: MonthlyFeeIncDecStep, value: field.value, onChange: field.onChange })) }) }), _jsx(PortfolioContainer, { children: _jsx(PortfolioCard, { viewType: 'card', displayState: 'default', portfolio: portfolio, showActionsBtn: false, customBadgeText: t('portfolio.create_portfolio.steps.portfolio_settings.preview_label', { ns: 'common' }), badgeStyles: {
                                height: 30,
                                top: '-30px',
                                padding: '5px 20px',
                                '&:after': {
                                    borderWidth: '0 20px 25px 0',
                                },
                            }, selected: true }) }), _jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { IconComponent: TriangleIcon, color: theme.palette.warning.main, size: 16 })), text: t('portfolio.create_portfolio.steps.portfolio_settings.hint_text', { ns: 'common' }) })] })), contentAlign: 'start', buttons: (_jsx(Button, { type: 'submit', size: 'large', disabled: Boolean(hasErrors), fullWidth: true, children: t('portfolio.create_portfolio.steps.portfolio_settings.continue_btn', { ns: 'common' }) })) }) }));
};
export default PortfolioSettings;
